/**!
 *  @name				   app.js (assets/js/components/app.js)
 *
 *  @package       COMPONENTS
 *  @description	 tba.
 *  @copyright 	   (c) 2020 Ansgar Hiller <ansgar@weigelstein.de>
 */

import $ from 'jquery';
import 'jquery-ui';
import 'waypoints/src/waypoint.js';
import enquire from 'enquire.js';
import './../widgets/chosenizer';
import Cookies from 'js-cookie';
import imagesLoaded from 'imagesloaded';
import './../widgets/pager';
import './../widgets/toggle_box';
import './../widgets/bulk_action_list';
imagesLoaded.makeJQueryPlugin($);

require('bowser/bundled');
import Bowser from 'bowser';

const
Hoverable = require('./../helper/hoverable'),
ApiButton = require('./../helper/api_button');

const DEVICE = Bowser.parse(window.navigator.userAgent);

class App
{
		/*
		 *	READ-ONLY
		 * 	====================== */

		get BP()
		{
				return {
					xs: 0,
				 	sm: 576,
				 	md: 768,
				 	lg: 992,
				 	xl: 1200,
				 xxl: 1600
				};
		}
		get HEADER_HEIGHTS()
		{
				return {
				  xs: 44,
				  sm: 48,
				  md: 50,
				  lg: 52,
				  xl: 54,
				 xxl: 60
				};
		}
		get GRID_FLOAT_BREAKPOINT() { return this.BP.lg; }
		get HERO_RATIO_DEFAULT() { return 0.5625; } // 16:9 (0.5625)
		get BODY() 		{ return $('body'); }
		get HTML() 		{ return $('html'); }
		get PAGE() 		{ return $('#page') 		|| []; }
		get HEADER() 	{ return $('#header') 	|| []; }
		get HERO() 		{ return $('#hero') 		|| []; }
		get MAIN() 		{ return $('#main') 		|| []; }
		get SIDEBAR() { return $('#sidebar') 	|| []; }
		get FOOTER() 	{ return $('#footer') 	|| []; }

		get PAGERS() { return this._pagers; }

		get w() { return Waypoint.viewportWidth(); }
		get h() { return Waypoint.viewportHeight(); }
		get scrollTop() { return $(window).scrollTop(); }
		get useSmallScreenBehavior() { return (this.w < this.GRID_FLOAT_BREAKPOINT); }
		get isiOS() 		{ return (DEVICE.os.name.toLowerCase() === 'ios'); }
		get isAndroid() { return (DEVICE.os.name.toLowerCase() === 'android'); }
		get isMobile() 	{ return (DEVICE.platform.type === 'mobile'); }
		get isMobile() 	{ return (DEVICE.platform.type === 'tablet'); }
		get isFirefox() { return (DEVICE.browser.name.toLowerCase() === 'firefox'); }
		get headerHeight() { return this.HEADER_HEIGHTS[this._currentBP]; }
		get currentBP() { return this._currentBP; }

		/*
		 *	GETTER/SETTER
		 * 	====================== */

		/*
		 *	App::isSidebarExpanded [boolean]
		 */
		get isSidebarExpanded()
		{
				if (typeof Cookies.get('sidebar-aria-expanded') !== 'undefined')
				{
						this._isSidebarExpanded = (Cookies.get('sidebar-aria-expanded') === 'true')? true : false;
				} else {
						Cookies.set('sidebar-aria-expanded', this._isSidebarExpanded.toString());
				};

				return this._isSidebarExpanded;
		}
		set isSidebarExpanded(bol)
		{
				this._isSidebarExpanded = bol;
				this._sidebarBtn.attr('aria-expanded', this._isSidebarExpanded.toString());
				if (this._isSidebarExpanded)
				{
						this.PAGE.addClass('sidebar-shown').removeClass('sidebar-hidden');
				} else {
						this.PAGE.addClass('sidebar-hidden').removeClass('sidebar-shown');
				}
				Cookies.set('sidebar-aria-expanded', this._isSidebarExpanded.toString());
		}

		constructor(doc)
		{
				if (DEBUG) console.log(`App::constructor`);

				this._doc = doc;
				this._currentBP = null;
				this._scrollTop = 0;
				this._lastScrollTop = 0;
				this._sidebarBtn = $('button[name="toggle-sidebar-button"]').first() || false;
				this._isSidebarExpanded = (this._sidebarBtn && this._sidebarBtn.attr('aria-expanded') === 'true')? true : false;
				this._pagers = {};
				this._backdrop = $('<div/>').addClass('backdrop fade');

				if (!this._sidebarBtn.length) {
						this.PAGE.addClass('sidebar-hidden');
				}

				this._onStartEvents = $.Callbacks();
				this._onBreakpointChangeEvents = $.Callbacks();
				this._onRefreshEvents = $.Callbacks();
				this._onRefreshOnceEvents = $.Callbacks();
				this._onScrollStartEvents = $.Callbacks();
				this._onScrollEndEvents = $.Callbacks();

				this.BODY.addClass(DEVICE.os.name.toLowerCase() + ' ' + DEVICE.platform.type + ' ' + DEVICE.browser.name.toLowerCase());

				enquire.register('(min-width: ' + ( this.BP.xxl ) + 'px)', {
						match: this._onBreakpointChange.bind(this, 'xxl')
				});
				enquire.register('(min-width: ' + ( this.BP.xl ) + 'px) and (max-width: ' + ( this.BP.xxl - 1) + 'px)', {
						match: this._onBreakpointChange.bind(this, 'xl')
				});
				enquire.register('(min-width: ' + ( this.BP.lg ) + 'px) and (max-width: ' + ( this.BP.xl - 1) + 'px)', {
						match: this._onBreakpointChange.bind(this, 'lg')
				});
				enquire.register('(min-width: ' + ( this.BP.md ) + 'px) and (max-width: ' + ( this.BP.lg - 1) + 'px)', {
						match: this._onBreakpointChange.bind(this, 'md')
				});
				enquire.register('(min-width: ' + ( this.BP.sm ) + 'px) and (max-width: ' + ( this.BP.md - 1) + 'px)', {
						match: this._onBreakpointChange.bind(this, 'sm')
				});
				enquire.register('(max-width: ' + ( this.BP.sm - 1) + 'px)', {
						match: this._onBreakpointChange.bind(this, 'xs')
				});

				$(window)
						.on(
								{
										'resize': this._resize.bind(this),
										'scroll': this._scroll.bind(this),
										'orientationchange': this._orientationChange.bind(this)
								}
						)
				;

				this._resizeTimer = null;
				this._scrollTimer = null;
				this._isScrolling = false;

				this._IMAGES = []; // contains all images of last App::preloadImages call
				this._BROKEN = []; // contains all broken images of last App::preloadImages call

				this._init();
		}

		/**!
		 *	private method called when a TWBS breakpoint changed due to window resizing
		 *	@param: bp [string] -> 'xxl', 'xl', 'lg' ...
		 */
		_onBreakpointChange(bp)
		{
				if (DEBUG) console.log(`App::_onBreakpointChange w/ param: bp = ${bp}`);

				this._currentBP = bp;
				this.BODY.removeClass('xs sm md lg xl xxl').addClass(bp);

				this.swapImagesByBreakpoint();
				this.swapBgImagesByBreakpoint();

				$(window).trigger('breakpointchange');

				this._onBreakpointChangeEvents.fire(this);
		}

		/**!
		 *	window.resize listener
		 *	@param: e [object] -> window.event
		 */
		_resize(e)
		{
				if (DEBUG && VERBOSITY >= 2) console.log(`App::_resize (native window.resize)`);

				clearTimeout(this._resizeTimer);

				this._refresh();

				this._resizeTimer = setTimeout(this._delayedResize.bind(this), 250);
		}

		/**!
		 *	window.orientationChange listener
		 *	@param: e [object] -> window.event
		 */
		_orientationChange(e)
		{
				if (DEBUG) console.log(`App::_orientationChange (native window.orientationchange)`);
				if (DEBUG && VERBOSITY >= 2) console.log(`Event: ${e.type}`);
				if (DEBUG && VERBOSITY >= 2) console.log('Window width: ' + this.w + ' / height: ' + this.h);
		}

		/**!
		 *	window.resize listener fired only once
		 *	@param: e [object] -> window.event
		 */
		_delayedResize(e)
		{
				if (DEBUG) console.log(`App::_delayedResize`);

				this._refreshOnce();

				$(window).trigger('delayed-resize',e);
		}

		/**!
		 *	window.scroll listener
		 *	@param: e [object] -> window.event
		 */
		_scroll(e)
		{
				if (DEBUG && VERBOSITY >= 2) console.log(`App::_scroll (native window.scroll)`);

				var _this = this;

				if (!this._isScrolling) {
						this._isScrolling = true;
						this._scrollStart(e);
				}
				clearTimeout(this._scrollTimer);
				this._scrollTimer = setTimeout(function(e){
            _this._scrollEnd(e);
            _this._isScrolling = false;
        }, 100);
		}

		/**!
		 *	window.scroll listener fired when scroll starts
		 *	@param: e [object] -> window.event
		 */
		_scrollStart(e)
		{
				if (DEBUG) console.log(`App::_scrollStart`);

				this.BODY.addClass('scrolling');
				this._lastScrollTop = this._scrollTop;

				$(window).trigger('scroll-start',e);

				this._onScrollStartEvents.fire(this);
		}

		/**!
		 *	window.scroll listener fired when scroll ends
		 *	@param: e [object] -> window.event
		 */
		_scrollEnd(e)
		{
				if (DEBUG) console.log(`App::_scrollEnd`);

				this.BODY.removeClass('scrolling');
				this._scrollTop = $(window).scrollTop();

				$(window).trigger('scroll-end',e);

				this._onScrollEndEvents.fire(this);
		}

		/**!
		 * 	App::_init
		 *
		 *	Basically wires all interactive elements
		 */
		_init()
		{
				if (DEBUG) console.log(`App::_init`);

				var _this = this;

				// Make sure, sidebar doesn't stay open on phones
				if (this.useSmallScreenBehavior) this.isSidebarExpanded = false;

				this._preloadImages({
						bg: true,
						onAlways: this._start.bind(this)
				}, this.BODY);

				this._wireChildren(this.BODY);

				/**
				 *  SIDEBAR
				 */
				if (this._sidebarBtn.length)
				{
						if (DEBUG && VERBOSITY >=2 ) console.log(`App::_init -> wire SIDEBAR-BUTTON`);
						if (this.isSidebarExpanded)
						{
								this.PAGE.addClass('sidebar-shown');
						} else {
								this.PAGE.addClass('sidebar-hidden');
						}
						this._sidebarBtn.on('click', function(e)
								{
										var
										_expanded = _this.isSidebarExpanded;

										_this.isSidebarExpanded = !(_expanded);
								}
						);
				}

				/*+
				 *	BACKDROP
				 */
				this.BODY.on('click', function()
		 				{
								var _bd = $('.backdrop');
								if (_bd.length)	_this.backdrop('hide');
		 				}
				);

		}

		/**!
		 * 	App::_wireChildren
		 *
		 *  Wire interactive dom-elements (call when dom was updated/replaced f.e. by an ajax-injection)
		 *	@param: parent [object]
		 */
		_wireChildren(parent)
		{
				if (typeof parent === 'undefined') parent = $('body');

				console.log(parent);

				if (DEBUG) console.log(`App::_wireChildren w/ parent = ${parent}`);

				/**
				 *  HOVERABLES
				 */
				var _hoverables = parent.find('.hoverable');
		    if (_hoverables.length)
		    {

if (DEBUG && VERBOSITY >=2 ) console.log(`
		WIRING HOVERABLES (.hoverable) \n
		================================================ \n
		${_hoverables.length} items found \n
`);

		        Hoverable.default(_hoverables);
		    }

				/**
				 *  API-BUTTON
				 */
				var _apiButtons = parent.find('.js-api');
		    if (_apiButtons.length)
		    {

if (DEBUG && VERBOSITY >=2 ) console.log(`
		WIRING API-BUTTONS (.js-api) \n
		================================================ \n
		${_apiButtons.length} items found \n
`);
						ApiButton.default(_apiButtons);
				}

				/**
				 *  CHOSENIZE
				 */
		    var _chzn = parent.find('select.chosenize');
		    if (_chzn.length)
		    {
						_chzn.each(function(i,el)
								{

if (DEBUG && VERBOSITY >=2 ) console.log(`
		CHOSENIZING SELECT-BOX (select.chosenize) \n
		================================================ \n
		name:		${$(el).attr('name')} \n
`);

										$(el).chosenizer();
								}
						);
		    }

				/**
				 *  BULK-ACTION-LISTS
				 */
		    var _bulk = parent.find('form.do-with-selected');
		    if (_bulk.length)
		    {
						var _this = this;

						_bulk.each(function(i,el)
								{
if (DEBUG && VERBOSITY >=2 ) console.log(`
		WIRING BULK-ACTION-LISTS (form.do-with-selected) \n
		================================================ \n
		name:		${$(el).attr('id')} \n
`);

										// _this._wireBulkActionList($(el));
										console.log(typeof $(el).bulkActionList);
										$(el).bulkActionList();
										$(el).bulkActionList('wireChildren');
								}
						);
		    }

				/**
				 *  NUMBER-GROUPS
				 */
		    var numberGroup = parent.find('.number-group');
		    if (numberGroup.length)
		    {
						numberGroup.each(function(i,el)
								{
if (DEBUG && VERBOSITY >=2 ) console.log(`
	  WIRING NUMBER-GROUP (.number-group) \n
		================================================ \n
		name:		${$(el).find('input').attr('name')} \n
`);

										var
										_btn  = $(el).find('.btn');

										_btn.off('click');
										_btn.on('click', function(e)
										{
												var
												_input = $(this).closest('.number-group').find('[type="number"]'),
												_val = Number(_input.val()),
												_max  = Number(_input.attr('max'))   || false,
												_min  = Number(_input.attr('min'))   || 0,
												_step = Number(_input.attr('step'))  || 1;

												if($(this).hasClass('add'))
												{
														if ((_max && _val < _max) || !_max) { _val += _step; }
												}
												if($(this).hasClass('substract'))
												{
														if (_val > _min) { _val -= _step; }
												}
												_input
														.val(Math.round(_val * 100)/100)
														.trigger('change');
										});
								}
						);
		    }

				/**
				 *  PAGERS
				 */
		    var _pagers = parent.find('.pager-widget');
		    if (_pagers.length)
		    {
						var _this = this;
		        _pagers
		            .each(function(i,el){
		                _this._pagers[$(el).data('id')] = $(el).pager();
		            }
		        );
		    }

				/**
				 *  TOGGLE-BOXES (Text-editor)
				 */
		    var _toggleboxes = parent.find('.toggle-editor-box');
		    if (_toggleboxes.length)
		    {
		        _toggleboxes
		            .each(function(i,el)
		            {
		                $(el).toggleBox();
		            }
		        );
		    }

				/**
				 *  TOGGLE-BUTTONS
				 */
				var _toggle = parent.find('[data-toggle]');
				if (_toggle.length)
				{
						_toggle.each(function(i,el)
								{
										var
										_btn    = $(el),
										_target = _btn.data('target') || _btn.attr('href'),
										_type   = _btn.data('toggle');

if (DEBUG && VERBOSITY >=2 ) console.log(`
    WIRING TOGGLE-BUTTON ([data-toggle]) \n
		================================================ \n
		type:		${_type} \n
		target:		${(typeof _target !== 'undefined')? _target : '--'} \n
`);

										switch(_type)
										{
												case 'collapse':
														// COLLAPSIBLES (are taken care of by TWBS)
														break;

												case 'dropdown':
		                        // DROPDOWNS (are taken care of by TWBS)
		                        break;

												case 'modal':
		                        // MODALS (are taken care of by TWBS)
		                        break;

		                    case 'popover':
		                        // console.log(_btn);
		                        _btn.popover();
		                        break;
										}
								}
						);
				}

				/**
				 *  LINKED-ITEM
				 */
				var _linkeditems = parent.find('[data-link]');
				if (_linkeditems.length)
				{
						_linkeditems.each(function(i,el)
								{
										$(el).on('click', function(e)
												{
														var
														_url = $(this).data('link'),
														_target = $(this).data('target') || '_self';

														window.open(_url,_target);
												}
										);
								}
						);
				}
		}

		/**!
		 * 	App::_refresh
		 *
		 *  Add dom-manipulations to occur on native window.resize (fired constantly during resizing)
		 */
		_refresh()
		{
				if (DEBUG) console.log(`App::_refresh`);

				/* SIDEBAR */
				if (this.SIDEBAR.length)
				{
						this.SIDEBAR.css({
								height: (this.h - this.HEADER_HEIGHTS[this._currentBP]) + 'px'
						});
				}

				this._onRefreshEvents.fire(this);
		}

		/**!
		 * 	App::_refreshOnce
		 *
		 *  Add dom-manipulations to occur on window.delayed-resize (fired only once after resizing finished)
		 */
		_refreshOnce()
		{
				if (DEBUG) console.log(`App::_refreshOnce`);

				this._onRefreshOnceEvents.fire(this);
		}

		/**!
		 * 	App::_start
		 *
		 *  called when all images are loaded
		 */
		_start()
		{
				if (DEBUG) console.log(`App::_start`);
				var _this = this;

				this._onStartEvents.fire(this);
		}

		/**!
		 * 	App::_preloadImages
		 *
		 *  preloads all images and tells you when it's done (ImagesLoaded.js)
		 *	@see: 		https://imagesloaded.desandro.com
		 * 	@params: 	options [object] =>
		 *			{
		 *							 bg [boolean] (default false),
		 *				 onAlways [function|null] (default null),
		 *				onSuccess [function|null] (default null),
		 *					 onFail [function|null] (default null),
		 *			 onProgress [function|null] (default null),
	 	 *			}
		 *	@param: parent [object] (default $(body))
		 */
		_preloadImages(options, parent)
		{
				if (typeof options === 'undefined') var options = {};
				if (typeof parent === 'undefined') var parent = this.BODY;

				this._IMAGES = [];
				this._BROKEN = [];

				var
				_this = this,
				_parent = parent,
				_opt = {
						// callbacks
						bg: 		    options.bg 			     || false,
						onAlways:	  options.onAlways  	 || null,
						onSuccess: 	options.onSuccess  	 || null,
						onFail:    	options.onFail		   || null,
						onProgress:	options.onProgress	 || null
				},
				_events = $.Callbacks(),
				_dispatch = function ( fn, params, image ) {
						if ( typeof fn === 'function' ) {
								_events
										.add(fn)
										.fire( params, image )
										.remove(fn);
						}
				};
				$.extend(_opt, options);

				_parent.imagesLoaded({background: _opt.bg}
						).always(
								function(obj) {
										_dispatch(_opt.onAlways,obj,null);
								}
						).done(
								function(obj) {
										_dispatch(_opt.onSucess,obj,null);
								}
						).fail(
								function(obj) {
										_dispatch(_opt.onFail,obj,null);

										if (DEBUG) console.log(`WARNING: ${_this._BROKEN.length} images are broken or missing:`);
				      			if ( _this._BROKEN.length ) {
				        				var _missing = {};
				        				for (var i = 0; i < _this._BROKEN.length; i++) {
				        					  _missing[(i+1)] = _this._BROKEN[i].img.src;
				        				}
				        				if (DEBUG && VERBOSITY >= 1) console.log(_missing);
				      			}
								}
						).progress(
								function(obj, image) {
										_dispatch(_opt.onProgress,obj,image);

										if (!image.isLoaded) //	mend broken images
				      			{
				        				//	remove 'ready' state
				        				$(image.img)
				          					.removeClass('ready')
				          					.parent()
				          					.removeClass('ready');

				        				if ($(image.img).hasClass('generated'))
				        				{
				        					  $(image.img).remove(); // throw out if broken image was dynamically generated ...
				        				} else
				        				{
				        					  $(image.img).addClass('hidden'); // ... or hide
				        				}
				        				// collect broken images
				        				_this._BROKEN.push(image);
				      			} else
				      			{	// collect good images
				      				  _this._IMAGES.push(image);
				      			}
								}
						)
				;
		}

		/**!
		 * 	App::on
		 *
		 *	Add callbacks
		 *	@param: event [string]
		 *	@param: fn [function]
		 */
		on(event, fn)
		{
				if (typeof fn === 'function')
				{
						switch(event) {
								case 'start':
										this._onStartEvents.add(fn);
										break;
								case 'breakpointChange':
										this._onBreakpointChangeEvents.add(fn);
										break;
								case 'refresh':
										this._onRefreshEvents.add(fn);
										break;
								case 'refreshOnce':
										this._onRefreshOnceEvents.add(fn);
										break;
								case 'scrollStart':
										this._onScrollStartEvents.add(fn);
										break;
								case 'scrollEnd':
										this._onScrollEndEvents.add(fn);
										break;
						}
				}
		}

		/**!
		 * 	App::off
		 *
		 *	Remove callbacks
		 *	@param: event [string]
		 *	@param: fn [function]
		 */
		off(event, fn)
		{
				if (typeof fn === 'function')
				{
						switch(event) {
								case 'start':
										this._onStartEvents.remove(fn);
										break;
								case 'breakpointChange':
										this._onBreakpointChangeEvents.remove(fn);
										break;
								case 'refresh':
										this._onRefreshEvents.remove(fn);
										break;
								case 'refreshOnce':
										this._onRefreshOnceEvents.remove(fn);
										break;
								case 'scrollStart':
										this._onScrollStartEvents.remove(fn);
										break;
								case 'scrollEnd':
										this._onScrollEndEvents.remove(fn);
										break;
						}
				}
		}

		/**!
		 * 	App::off
		 *
		 *	Remove callbacks
		 *	@param: event [string]
		 *	@param: fn [function]
		 */
		backdrop(mode = 'show')
		{
				if (DEBUG) console.log(`App::backdrop w/ mode = ${mode}`);
				switch(mode) {
						case 'show':
								this.BODY
										.addClass('backdrop-open')
										.append(this._backdrop);
								this._backdrop
										.addClass('show');
						break;
						case 'hide':
								this._backdrop.removeClass('show');
								this.BODY.removeClass('backdrop-open');
								gsap.delayedCall(1, this.backdrop.bind(this), ['remove']);
						break;
						case 'remove':
								this._backdrop.detach();
						break;
				}
		}

		/**!
		 * 	App::swapImagesByBreakpoint
		 *
		 *  Replaces image src w/ appropriate src string from data attributes according to this.currentBP
		 *	F.e.: <img src="path/to/img.jpg" data-xxl="path/to/img_xxl.jpg" data-xl="... />
		 *	Is typically called by App::_onBreakpointChange or App::_refreshOnce
		 */
		swapImagesByBreakpoint(parent)
		{
				if (typeof parent === 'undefined') var parent = this.BODY;

				var
				_bp = this.currentBP,
				_parent = parent;

				_parent.each(function(i,el)
						{
								var _original = $(el).data('lazy') || $(el).attr('src');

								if (!$(el).data('original'))
								{
										$(el).data({
												original : _original
										});
								}

								var _newSrc = ( $(el).data(_bp) !== undefined ) ? $(el).data(_bp) : $(el).data('original');

								if ( $(el).attr('src') )
								{
										$(el)
												.removeClass('done')
												.addClass('loading')
												.attr({
														src: _newSrc
												})
												.imagesLoaded()
												.progress(function(obj,image)
												{
													if (DEBUG && VERBOSITY >= 1) console.log(`App::swapImagesByBreakpoint w/ bp = ${_bp} => progress`);

													$(image.img)
															.attr('height',(image.img.naturalHeight > 0) ? image.img.naturalHeight : '100%')
															.attr('width',(image.img.naturalWidth > 0) ? image.img.naturalWidth : '100%')
															.addClass('done')
															.removeClass('loading');
												})
												.done (function(obj)
												{
														if (DEBUG && VERBOSITY >= 1) console.log(`App::swapImagesByBreakpoint w/ bp = ${_bp} => done`);
														if (DEBUG && VERBOSITY >= 1) console.log($(obj.elements));
												})
										;
								} else {
										$(el).data('lazy',_newSrc);
								}
						}
				);
		}

		/**!
		 * 	App::swapBgImagesByBreakpoint
		 *
		 *	Same as above, only for background-images f.e.: <div style="background-image:url(path/to/img.jpg);" />
		 */
		swapBgImagesByBreakpoint(elements)
		{
				if (typeof elements === 'undefined') var elements = this.MAIN;

				var
				_bp = this.currentBP,
				_elements = elements;

				_elements.each(function(i,el)
						{
								$(el).addClass('loading');
								$(el)
										.imagesLoaded({background: true})
										.done(function(obj) {
												$(obj.elements).removeClass('loading');
										});

								if ( $(el).data(_bp) === undefined )
								{
										$(el).css({'background-image' : 'url(' + $(el).data('lazy') + ')'});
								} else {
										$(el).css({'background-image' : 'url(' + $(el).data(_bp) + ')'});
								}
						}
				);
		}

		/*
		 *	PUBLIC METHODS (Interface)
		 */

		 /**!
 		 * 	App::preloadImages
 		 *
 		 *  preloads all images and tells you when it's done (ImagesLoaded.js)
 		 *	@see: 		https://imagesloaded.desandro.com
 		 * 	@params: 	options [object] =>
 		 *			{
 		 *							 bg [boolean] (default false),
 		 *				 onAlways [function|null] (default null),
 		 *				onSuccess [function|null] (default null),
 		 *					 onFail [function|null] (default null),
 		 *			 onProgress [function|null] (default null),
 	 	 *			}
 		 *	@param: parent [object] (default $(body))
 		 */
		preloadImages(options, parent)
		{
				this._preloadImages(options, parent);
		}

		/**!
		 * 	App::wireChildren
		 *
		 *  Wire interactive dom-elements (call when dom was updated/replaced f.e. by an ajax-injection)
		 *	@param: parent [object]
		 */
		wireChildren(parent)
		{
				this._wireChildren(parent);
		}

		/**!
		 * 	App::wireBulkActionList
		 *
		 *  Wire interactive dom-elements of bulk-action-lists [form.do-with-selected] (call when dom was updated/replaced f.e. by an ajax-injection)
		 *	@param: parent [object]
		 */
		wireBulkActionList(list)
		{
				if (typeof list.bulkActionList === 'function')
				{
						list.bulkActionList('wireChildren');
				}
		}

		/**!
		 *	Helper to calc scroll-animation durations
		 *	@param: h [int] -> height of the element to animate while visible in vieport
		 */
		getWindowHeight(h)
		{
				if (DEBUG) console.log(`App::getWindowHeight w/ param: h = ${h}`);

				if (h) {
						return this.h + h;
				} else {
						return this.h;
				}
		}
}

export default App;
