/**!
 *  @name				   api_button.js (assets/js/helper/api_button.js)
 *
 *  @client	       NORDPOL-900
 *  @package       HELPER
 *  @description	 Configure buttons w/ API-PLATFORM params in data attributes
 *  @copyright 	   (c) 2019 Ansgar Hiller <ansgar@weigelstein.de>
 */

import $ from 'jquery';
import { gsap } from 'gsap';
import { Power1 } from 'gsap';
import { CSSPlugin } from 'gsap';

var DEFAULT_TYPE = "GET";

var PAGER;

export default function($elements)
{
    if (DEBUG && VERBOSITY >= 1) console.log(`Configuring ${$elements.length} api_buttons`);

    $elements.each(function(i,el)
    {
        $(el).on('click', function(e)
        {
            e.preventDefault();

            var
            _that = $(this),
            _data = $(this).data(),
            _url  = _data.url || $(this).attr('href'),
            _type = _data.method || DEFAULT_TYPE,
            _dataType = _data.dataType || 'json';

            PAGER = $('#PAGER_' + $(this).data('target'));

            if (_data.enabled !== 'undefined' && typeof _data.enabled === 'number') _data.enabled = !!+_data.enabled;

            if (DEBUG && VERBOSITY === 3) console.log(PAGER);

            if (!_url) return false;

            $.ajax({
                url: _url,
                type: _type,
                contentType: "application/json",
                dataType: _dataType,
                data: JSON.stringify(_data),
            }).then(
                (data, jqXHR, opt, e) => {
                    if (DEBUG && VERBOSITY === 3)
                    {
                        console.log('================================================');
                        console.log('AJAX Response Objects: data // jqXHR // opt // e');
                        console.log('================================================');
                        if (_dataType === 'json') console.log(data);
                        console.log(jqXHR);
                        console.log(opt);
                        console.log(e);
                        console.log('================================================');
                    }

                    var
                    _status = opt.status,
                    _item = _that.closest('.table-row');

                    if (DEBUG && VERBOSITY >= 1) console.log('status: ' + _status);

                    if (_data.redirect && $.inArray(_status,['200','202','204'])) // REDIRECT
                    {
                        if (DEBUG && VERBOSITY >= 1) console.log(`Redirecting to: ${_data.redirect}`);
                        window.open(_data.redirect, '_self');
                    } else
                    if (_item && _status === 204) // DELETE
                    {
                        console.log(_item);
                        _item.addClass('disabled');
                        gsap.to(_item, {
                            duration: .3,
                            opacity: 0,
                            ease: Power1.easeOut,
                            onComplete: function()
                            {
                                _item.remove();
                                PAGER.pager('loadPage');
                            }
                        });
                    } else
                    if (_item && _status === 200) // PUT
                    {
                        var
                        _parent = _item.parent(),
                        _new_item = $(data);
                        console.log(_parent);
                        _new_item.addClass('updated')
                        _item.replaceWith(_new_item);
                        WS.API.wireChildren(_new_item);
                        gsap.delayedCall(1.5, function() { _new_item.removeClass('updated'); });
                    }

                    $(window).trigger('API', [{
                            type: _type,
                        dataType: _dataType,
                            data: (_dataType === 'json')? data : {}
                    }]);
                }
            );
        });
    });
};
