/**!
 *  BULK-ACTION-LIST
 *
 *  @name			      bulk_action_list.js (js/widgets/bulk_action_list.js)
 *
 *  @client	        NORDPOL-900
 *  @package        WIDGETS
 *  @description	  t.b.a.
 *  @copyright 	    (c) 2020 Ansgar Hiller <ansgar@weigelstein.de>
*/

$(function()
{
    $.widget('widgets.bulkActionList',
    {
        // default options
        options: {
						id: null,
						checked: 0,
        },

        // The constructor
        _create: function()
        {
						if ('FORM' !== this.element[0].tagName || typeof this.element.attr('id') === 'undefined') return false;

						this.options.id = this.element.attr('id');

						$.extend(this.options, this.element.data());

						if (DEBUG) console.log(`widgets.bulkActionList: _create w/ id = "${this.options.id}"`);

						this._setOption('checked', 0);

						this._wireChildren();
				},

				_init: function()
				{
						if (DEBUG) console.log(`widgets.bulkActionList: _init w/ id = "${this.options.id}"`);

						this._on(
								this.element, {'ITEMS-SELECTED': '_itemsSelected'}
						);
				},

				_wireChildren: function()
				{
						if (DEBUG) console.log(`widgets.bulkActionList: _wireChildren w/ id = "${this.options.id}"`);

						// ... action buttons
						this.buttons = $('.btn[data-id="'+ this.options.id.replace('FORM_','') +'"]') || false;

						// ... and action choiceTypes
						this.choiceTypes = $('.js-bulk > .chosenize[data-id="'+ this.options.id.replace('FORM_','') +'"]') || false;
						if (this.choiceTypes)
						{
								this.choiceTypes.trigger('chosen:updated');

								this.choiceTypes.each(function(i,el)
								{
										if ($(el).data('method') === 'PUT')
										{
												$(el)
														.prop('disabled', true)
														.trigger('chosen:updated');
										}
								});
						}

            // ... the TOGGLE-ALL-CHECKBOX ...
						this.toggle_all = this.element.find('.toggle-all') || false;
						if (this.toggle_all)
						{
								this._on(this.toggle_all, {'change': '_toggleAll'});
						}

            // ... and the other CHECKBOXES
						this.checkboxes = this.element.find('input.row-selector') || false;
						if (this.checkboxes)
						{
								var
								_this = this,
								_el = this.element;

								this.checkboxes.each(function(i,el)
								{
										if ('INPUT' !== $(el)[0].tagName || 'checkbox' !== $(el).attr('type')) return false;

										$(el).on('change', function(e)
										{
												var
												_id = $(e.target).val(),
												_checked = _this.options.checked;

												if (e.target.checked) {
														_this.options.checked += 1;
														_el.trigger('selected',[_id]);
												} else {
														_this.options.checked -= 1;
														_el.trigger('deselected',[_id]);
												}

												var count = _this.options.checked;

												_el.trigger('ITEMS-SELECTED',[count]);
										});
								});
						}
				},

				_itemsSelected: function(e, count)
				{
						if (DEBUG) console.log(`widgets.bulkActionList: _itemsSelected w/ count = "${count}"`);

						if (count > 0) {
								this.buttons
										.removeAttr('disabled','disabled');

								this.choiceTypes
										.prop('disabled', false)
										.trigger('chosen:updated');

						} else {
								this.buttons
										.attr('disabled','disabled');

								this.choiceTypes
										.trigger('chosen:updated');

								this.choiceTypes.each(function(i,el){
										if ($(el).data('method') === 'PUT') {
												$(el)
														.prop('disabled', true)
														.trigger('chosen:updated');
										}
								});
						}
				},

				_toggleAll: function(e)
				{
						var
						_this = this,
						_el = this.element,
						_state = e.target.checked,
						_targets = _el.find('input.row-selector'),
						_ids = [];

						if (DEBUG) console.log(`widgets.bulkActionList: _toggleAll for ${_targets.length} targets`);

						this._setOption('checked', 0);

						_targets.each(function(i,el)
						{
								if (!$(el).attr('disabled')) {
										$(el).prop('checked',_state);
										if (_state) {
												_this.options.checked += 1;
												_ids.push($(el).val());
										}
								}
						});

						var
						_count = this.options.checked;

						_el.trigger('toggle', [_ids]);
						_el.trigger('ITEMS-SELECTED',[_count]);
				},

				_setOption: function(key, value)
				{
						if (DEBUG) console.log(`widgets.bulkActionList: _setOption w/ key = ${key} and value = ${value}`);

						if (key === 'checked')
						{
								this.element.data().checked = value;
						}

						this._super(key, value);
				},

				wireChildren()
				{
						if (DEBUG) console.log(`widgets.bulkActionList: wireChildren`);

						this._wireChildren();
				}
		});
});
