/**!
 *  TOGGLE-BOX
 *
 *  @name			      toggle_box.js (js/widgets/toggle_box.js)
 *
 *  @client	        NORDPOL-900
 *  @package        WIDGETS
 *  @description	  t.b.a.
 *  @copyright 	    (c) 2020 Ansgar Hiller <ansgar@weigelstein.de>
*/

$(function()
{
    $.widget('widgets.toggleBox',
    {
        // default options
        options: {
            id: null,
            mode: 'read',
            display: 'block',
        },

        // The constructor
        _create: function()
        {
            if (DEBUG) console.log('widgets.toggleBox: _create');

						$.extend(this.options, this.element.data());

						this.readbox = this.element.find('#' + this.options.id + '_read');
						this.editbox = this.element.find('#' + this.options.id + '_edit');
            this.editbtn = this.readbox; // turn the whole box into the edit button
            this.cancelbtn = this.editbox.find('.btn-cancel');

            if (!this.readbox.length || !this.editbox.length) return false;
				},

        _init: function()
        {
            if (DEBUG) console.log('widgets.toggleBox: _init');

            this._on(this.editbtn,   {'click': '_toggle'});
            this._on(this.cancelbtn,  {'click': '_toggle'});

            this._toggle({}, this.options.mode);
        },

        _toggle: function(e, mode)
        {
            if (DEBUG) console.log('widgets.toggleBox: _toggle');

            if (typeof mode === 'undefined')
            {
                mode = (this.options.mode == 'read')? 'edit' : 'read';
            }

            switch(mode) {
                case 'read':
                    this.readbox
                        .addClass('d-' + this.options.display)
                        .removeClass('d-none');
                    this.editbox
                        .addClass('d-none')
                        .removeClass('d-' + this.options.display);
                    this.options.mode = 'read';
                break;
                default:
                case 'edit':
                    this.editbox
                        .addClass('d-' + this.options.display)
                        .removeClass('d-none');
                    this.readbox
                        .addClass('d-none')
                        .removeClass('d-' + this.options.display);
                    this.options.mode = 'edit';
                break;
            }
        }
		});
});
