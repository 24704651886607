/**!
 *  @name				   utils.js (js/helper/utils.js)
 *
 *  @package       UTILS
 *  @description	 tba.
 *  @copyright 	   (c) 2020 Ansgar Hiller <ansgar@weigelstein.de>
 */

class Utils
{
		/**
		 *	Strip GET params (everything following the '?') from current browser location
		 */
		static refineURL()
		{
				console.log('refineURL');
				// get the string following the '?'
				var
				query = window.location.search.substring(1);

				// is there anything there '?'
				if(query.length)
				{
					 // are the new history methods available ?
					 if(window.history != undefined && window.history.pushState != undefined)
					 {
								// if pushstate exists, add a new state to the history, this changes the url without reloading the page
								window.history.pushState({}, document.title, window.location.pathname);
					 }
				}
		}

		/**
		 * Test for valid email address
		 */
		static validateEmail(email)
		{
		  	var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		  	return re.test(email);
		}

		/**
		 * Transform line-breaks for HTML text
		 *
		 * example 1: nl2br('Kevin\nvan\nZonneveld');
		 * returns 1: 'Kevin<br />\nvan<br />\nZonneveld'
		 * example 2: nl2br("\nOne\nTwo\n\nThree\n", false);
		 * returns 2: '<br>\nOne<br>\nTwo<br>\n<br>\nThree<br>\n'
		 * example 3: nl2br("\nOne\nTwo\n\nThree\n", true);
		 * returns 3: '<br />\nOne<br />\nTwo<br />\n<br />\nThree<br />\n'
		 */
		static nl2br(str, is_xhtml)
		{
		    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>';
				// Adjust comment to avoid issue on phpjs.org display

		    return (str + '')
		      .replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
		}
}

export default Utils;
