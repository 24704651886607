/**!
 *  @name				   hoverable.js (assets/js/helper/hoverable.js)
 *
 *  @client	       NORDPOL-900
 *  @package       HELPER
 *  @description	 toggles a class 'hover' on 'mouseenter/mouseleave' events for selected elements
 *  @copyright 	   (c) 2019 Ansgar Hiller <ansgar@weigelstein.de>
 */

import $ from 'jquery';

export default function($elements)
{
    if (DEBUG && VERBOSITY >= 1) console.log(`Configuring ${$elements.length} hoverables`);

    $elements.each(function(i,el)
    {
        $(el).on('mouseenter mouseleave click', function(e)
            {
                var
                _target = $(e.currentTarget),
                _link = _target.data('link') || false,
                _linkTarget = _target.data('linkTarget') || '_top',
                _timer;

                switch(e.type) {
                    case 'mouseenter':
                        clearTimeout(_timer);
                        _target.addClass('hover');

                        break;

                    default:
                    case 'mouseleave':
                        clearTimeout(_timer);
                        $(window).trigger('update',e);
                        _timer = setTimeout(function () {
                            _target.removeClass('hover');
                        }, 150);

                        break;
                    case 'click':
                        if (!_target.hasClass('disabled') && _link)
                        {
                            window.open(_link, _linkTarget);
                        }
                        break;
                }
            }
        );
    });
};
